.aws-btn {
    --button-default-height: 44px;
    --button-default-font-size: 12px;
    --button-default-border-radius: 4px;
    --button-horizontal-padding: 16px;
    --button-raise-level: 4px;
    --button-hover-pressure: 1;
    --transform-speed: 0.15s;
    --button-primary-color: hsl(53, 88%, 73%);
    --button-primary-color-dark: hsl(53, 88%, 48%);
    --button-primary-color-light: hsl(220, 6%, 9%);
    --button-primary-color-hover: hsl(53, 88%, 68%);
    --button-primary-color-active: hsl(53, 88%, 63%);
    --button-primary-border: none;
    --button-secondary-color: #12baed;
    --button-secondary-color-dark: #1e88e5;
    --button-secondary-color-light: hsl(0, 0%, 100%);
    --button-secondary-color-hover: #1e88e5;
    --button-secondary-color-active: #cfdee9;
    --button-secondary-border: 2px solid #1e88e5;
    --button-anchor-color: hsl(208, 81%, 29%);
    --button-anchor-color-dark: hsl(208, 81%, 15%);
    --button-anchor-color-light: #ffffff;
    --button-anchor-color-hover: hsl(208, 81%, 27%);
    --button-anchor-color-active: hsl(208, 81%, 25%);
    --button-anchor-border: none;
    --button-danger-color: hsl(0, 62%, 47%);
    --button-danger-color-dark: hsl(0, 62%, 32%);
    --button-danger-color-light: hsl(0, 0%, 99%);
    --button-danger-color-hover: hsl(0, 62%, 45%);
    --button-danger-color-active: hsl(0, 62%, 42%);
    --button-danger-border: none;
}