@keyframes slide {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-178%);
    }
}

.animate-slide {
    animation: slide 20s linear infinite;
}

/* Mobile adjustments */
@media (max-width: 640px) {
    @keyframes slide {
        from {
            transform: translateX(0);
        }

        /* Translate by 100% so all logos go through before repeat */
        to {
            transform: translateX(-335%);
        }
    }

    .animate-slide {
        /* Increase duration if needed so that the logos scroll at a pace that lets users see them */
        animation-duration: 20s;
    }
}

@keyframes fadeInFadeOut {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.wordFadeInFadeOut {
    animation: fadeInFadeOut 4s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.blink {
    animation: blink 2s linear infinite;
}

.gradient-link:hover {
    background: linear-gradient(to right, hsl(287, 86%, 55%), hsl(287, 86%, 45%));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 5120px) {

    /* --- Scrollbar track and handle --- */
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #f8f8f8;
    }

    ::-webkit-scrollbar-thumb {
        background: #18b7e74d;
        border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #18B6E7;
    }
}

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

svg {
    max-width: 100%;
    max-height: 100%;
}